<template>
  <div class="d-flex flex-column" style="height: 100%">
    <VOverlay
      :opacity="0.7"
      :value="loaders.fetchAreas || loaders.changeAreas || loaders.deleteAreas"
    >
      <VProgressCircular
        color="#E43A35"
        indeterminate
        :size="80"
        :width="6"
      />
    </VOverlay>
    <div class="ma-7 d-flex align-center">
      <span class="page_title">Зоны доставки</span>
      <div class="d-flex align-center pl-10">
        <span class="input-title pr-5">Город</span>
        <CitySelection @city-selected="city = $event" />
      </div>
      <div class="d-flex align-center pl-10">
        <span class="input-title pr-5">Заведение</span>
        <RestaurantSelection
          :city="city"
          @restaurantSelected="restaurant = $event"
        />
      </div>
      <div class="d-flex align-center pl-10">
        <span class="input-title pr-5">Тип зоны</span>
        <VSelect
          v-model="selectedAreaMode"
          dense
          hide-details
          :items="areaModes"
          outlined
          prepend-inner-icon="mdi-magnify"
        />
      </div>
    </div>
    <div class="flex-grow-1 d-flex">
      <AreasMap
        ref="areasMap"
        :areas-with-tariff="restaurantAreasWithTariff"
        :restaurant-location="restaurant ? serializedCoordinate : null"
        @changedSelectedAreas="setSelectedAreas"
      />
      <div
        v-if="restaurant && Object.keys(selectedAreas).length"
        class="d-flex flex-column map-right-extension-container"
      >
        <VCard class="d-flex flex-column ml-2 mr-2 pa-2" outlined>
          <div class="d-flex justify-space-between align-center mb-3">
            <span class="tariff-title">Условия доставки</span>
            <VBtn
              icon
              small
              @click="cancelSelectAreas"
            >
              <VIcon>mdi-close</VIcon>
            </VBtn>
          </div>
          <span class="extension-container-title mb-2">Тариф:</span>
          <DeliveryTariffSelection
            @tariffSelected="selectedTariff = $event"
          />
          <span
            class="extension-container-title mt-2 mb-2"
          >Время доставки:</span>
          <DeliveryTimeSelection
            @deliveryTimeSelected="selectedDeliveryTime = $event"
          />
          <VBtn
            class="default-btn white--text mt-4 mb-2"
            color="#4caf50"
            :disabled="!selectedTariff || !selectedDeliveryTime"
            rounded
            @click="changeSelectedAreas"
          >
            Изменить условия доставки
          </VBtn>
          <VBtn
            class="default-btn white--text mt-1 mb-2"
            color="#E43B35"
            rounded
            @click="deleteSelectedArea"
          >
            Удалить условия доставки
          </VBtn>
        </VCard>
      </div>
    </div>
  </div>
</template>

<script>
import CitySelection from '@/components/CitySelection.vue';
import AreasMap from '@/components/AreasMap.vue';
import RestaurantSelection from '@/components/RestaurantSelection.vue';
import {
  deliveryAreaAddConditions,
  deliveryAreaDeleteConditions,
  fetchDeliveryAreaWithTariffList,
} from '@/api/api';
import DeliveryTariffSelection from '@/components/DeliveryTariffSelection.vue';
import DeliveryTimeSelection from '@/components/DeliveryTimeSelection.vue';
import { serializeCoordinate } from '@/serializers/coordinateSerializer';

export default {
  name: 'DeliveryAreas',
  components: {
    DeliveryTimeSelection,
    DeliveryTariffSelection,
    RestaurantSelection,
    CitySelection,
    AreasMap,
  },
  data() {
    return {
      city: null,
      restaurant: null,
      restaurantAreasWithTariff: [],
      selectedAreas: {},
      selectedTariff: {},
      selectedDeliveryTime: {},
      loaders: {
        fetchAreas: false,
        changeAreas: false,
        deleteAreas: false,
      },
      areaModes: [
        { value: 'default', text: 'Обычные' },
        { value: 'reduced', text: 'Суженые' },
      ],
      selectedAreaMode: 'default',
      allAreas: {
        default: [],
        reduced: [],
      },
      isReducedArea: false,
    };
  },
  methods: {
    fetchRestaurantAreas() {
      this.selectedAreas = {};
      this.restaurantAreasWithTariff = [];
      if (!this.restaurant) {
        return;
      }
      const restPk = this.restaurant.pk;

      this.loaders.fetchAreas = true;
      this.allAreas.default = [];
      this.allAreas.reduced = [];
      fetchDeliveryAreaWithTariffList(restPk).then((areasWithTariff) => {
        areasWithTariff.forEach((area) => {
          if (area.isReducedArea) {
            this.allAreas.reduced.push(area);
          } else {
            this.allAreas.default.push(area);
          }
        });
        this.restaurantAreasWithTariff = this.allAreas[this.selectedAreaMode];
        this.loaders.fetchAreas = false;
      });
    },
    setSelectedAreas(areas) {
      // Array of keys(str)
      this.selectedAreas = areas;
    },
    cancelSelectAreas() {
      this.$refs.areasMap.deselectSelectedAreas();
      this.selectedAreas = {};
    },
    changeSelectedAreas() {
      this.loaders.changeAreas = true;
      deliveryAreaAddConditions(
        this.selectedAreas,
        this.restaurant.pk,
        this.selectedTariff.pk,
        this.selectedDeliveryTime.pk,
        this.isReducedArea,
      )
        .then(() => {
          this.loaders.changeAreas = false;
          this.cancelSelectAreas();
          this.fetchRestaurantAreas();
        })
        .catch(() => {
          this.loaders.changeAreas = false;
        });
    },
    deleteSelectedArea() {
      this.loaders.deleteAreas = true;
      deliveryAreaDeleteConditions(
        this.selectedAreas,
        this.restaurant.pk,
        this.isReducedArea,
      )
        .then(() => {
          this.loaders.deleteAreas = false;
          this.fetchRestaurantAreas();
        })
        .catch(() => {
          this.loaders.deleteAreas = false;
        });
    },
  },
  computed: {
    serializedCoordinate() {
      return serializeCoordinate(this.restaurant.location.coordinate);
    },
  },
  watch: {
    restaurant() {
      this.fetchRestaurantAreas();
    },
    selectedAreaMode(mode) {
      this.restaurantAreasWithTariff = this.allAreas[mode];
      this.isReducedArea = mode === 'reduced';
    },
  },
  mounted() {
    this.$root.$on('updateRestaurantAreas', () => {
      this.fetchRestaurantAreas();
    });
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/scss/page.scss';

.map-right-extension-container {
  width: 450px;
}
.tariff-title {
  color: $default_dark_gray_color;
  font-family: $default_font;
  font-size: 20px;
}
.extension-container-title {
  color: $default_dark_gray_color;
  font-family: $default_font;
  font-size: 16px;
}
.extension-container-subtitle {
  color: $default_dark_gray_color;
  font-family: $default_font;
  font-size: 13px;
}
</style>
