<template>
  <div>
    <VAutocomplete
      v-model="selectedTariff"
      class="selection"
      clearable
      dense
      hide-details
      hide-no-data
      hide-selected
      item-text="title"
      :items="tariffs"
      outlined
      placeholder="Выберите тариф"
      prepend-inner-icon="mdi-magnify"
      return-object
    />
  </div>
</template>

<script>
import { fetchDeliveryTariffs } from '@/api/api';

export default {
  name: 'DeliveryTariffSelection',
  emits: ['tariffSelected'],
  data() {
    return {
      selectedTariff: null,
      tariffs: [],
    };
  },
  beforeCreate() {
    fetchDeliveryTariffs().then((tariffs) => {
      this.tariffs = tariffs;
    });
  },
  watch: {
    selectedTariff(selectedTariff) {
      this.$emit('tariffSelected', selectedTariff);
    },
  },
};
</script>
