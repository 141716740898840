<template>
  <div>
    <VAutocomplete
      v-model="selectedDeliveryTime"
      clearable
      dense
      hide-details
      hide-no-data
      hide-selected
      item-text="title"
      :items="deliveryTimes"
      outlined
      placeholder="Выберите время доставки"
      prepend-inner-icon="mdi-magnify"
      return-object
    />
  </div>
</template>

<script>
import { fetchDeliveryTimes } from '@/api/api';

export default {
  name: 'DeliveryTimeSelection',
  emits: ['deliveryTimeSelected'],
  data() {
    return {
      selectedDeliveryTime: null,
      deliveryTimes: [],
    };
  },
  beforeCreate() {
    fetchDeliveryTimes().then((deliveryTimes) => {
      this.deliveryTimes = deliveryTimes;
    });
  },
  watch: {
    selectedDeliveryTime(selectedDeliveryTime) {
      this.$emit('deliveryTimeSelected', selectedDeliveryTime);
    },
  },
};
</script>
