<template>
  <VContainer class="pa-0 ma-0 areas-map-wrapper" fluid>
    <AreaLegends
      :areas-with-tariff="areasWithTariff"
      class="area-legends"
      @areaLegendLength="setAreaLegendLength"
      @areasWithTariffSelected="areasWithTariffSelected"
    />
    <UploadCsvFile :area-legends-length="areaLegendLength" />
    <div id="leaflet-map" class="map" />
    <VMenu
      v-model="circleAreaMenu.enabled"
      :position-x="circleAreaMenu.x"
      :position-y="circleAreaMenu.y"
    >
      <VCard class="pa-4">
        <div class="mb-2">
          Выберите радиус зоны доставки
        </div>
        <VBtn class="mr-1" @click="selectCircleRestaurantArea(2000)">
          2КМ
        </VBtn>
        <VBtn class="mr-1" @click="selectCircleRestaurantArea(3000)">
          3КМ
        </VBtn>
        <VBtn class="mr-1" @click="selectCircleRestaurantArea(3500)">
          3.5КМ
        </VBtn>
        <VBtn @click="selectCircleRestaurantArea(4000)">
          4КМ
        </VBtn>
      </VCard>
    </VMenu>
  </VContainer>
</template>

<script>
import L from 'leaflet';
import 'leaflet-lasso';
import {
  geoToH3, h3ToGeoBoundary, polyfill,
} from 'h3-js';
import settings from '@/global/global-settings';
import { Coordinate } from '@/serializers/coordinateSerializer';
import iconRestaurant from '@/assets/icon-restaurant.png';
import iconMarker from '@/assets/icon-marker.png';
import AreaLegends from '@/components/AreaLegends.vue';
import UploadCsvFile from '@/components/UploadCsvFile.vue';

export default {
  name: 'AreasMap',
  props: {
    restaurantLocation: [Coordinate],
    areasWithTariff: [Array], // Array of DeliveryAreaWithTariff
  },
  components: {
    AreaLegends,
    UploadCsvFile,
  },
  emits: ['changedSelectedAreas'],
  data() {
    return {
      map: null,
      restIcon: null,
      lassoControl: null,
      currentAreas: {},
      selectedAreas: {},
      circleAreaMenu: {
        enabled: false,
        x: 0,
        y: 0,
      },
      selectPollygon: false,
      markerGroups: {},
      pollygonAreas: [],
      areaLegendLength: null,
    };
  },
  mounted() {
    this.initMap();
  },

  methods: {
    setAreaLegendLength(length) {
      this.areaLegendLength = length;
    },
    // TODO: Move to mixin
    initMap() {
      this.map = L.map('leaflet-map', { zoomControl: false });
      this.markerGroups = L.featureGroup().addTo(this.map);
      L.tileLayer('https://tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1&ts=online_sd').addTo(this.map);
      L.control.zoom({ position: 'topright' }).addTo(this.map);

      this.pollygonalSelection();

      this.lassoControl = L.control.lasso().addTo(this.map);
      this.map.on('click', (event) => this.clickOnMap(event.latlng));
      this.map.on('contextmenu', (event) => this.rightClickOnMap(event.latlng));
      this.map.on('keypress', (event) => {
        if (event.originalEvent.code === 'KeyS') {
          this.lassoControl.toggle();
        } else if (event.originalEvent.code === 'KeyQ') {
          this.polygonActions(document.querySelector('.penta'));
        }
      });
      this.map.on('lasso.finished', (event) => this.lassoSelectLatLongs(event.latLngs));
      this.setupRestaurantLocation();
    },
    setupRestaurantLocation() {
      const mapCenter = this.restaurantLocation
        ? this.restaurantLocation.coordinates
        : [47.926363, 71.336273];
      const zoom = this.restaurantLocation ? 14 : 5.4;
      this.map.setView(mapCenter, zoom);

      if (this.restIcon) { this.restIcon.remove(); }
      if (this.restaurantLocation) {
        const icon = L.icon({ iconUrl: iconRestaurant, iconSize: [40, 40] });
        this.restIcon = L.marker(mapCenter, { icon }).addTo(this.map);
        this.restIcon.on('contextmenu', (event) => this.rightClickOnRestIcon(event));
      }
    },
    pollygonalSelection() {
      L.Control.Watermark = L.Control.extend({
        onAdd: () => {
          const span = L.DomUtil.create('span');
          span.classList = ['mdi mdi-pentagon-outline penta'];
          const styles = {
            width: '30px',
            height: '30px',
            backgroundColor: this.$vuetify.theme.defaults.dark.error,
            fontSize: '20px',
            textAlign: 'center',
            color: 'white',
            borderRadius: '5px',
            transition: 'color .5s, border-radius .5s, transform .3s',
          };
          Object.assign(span.style, styles);
          span.setAttribute('title', 'нарисовать по точкам (можно просто нажать кнопку: Q)');
          span.addEventListener('click', (e) => {
            e.stopPropagation();
            this.polygonActions(span);
          });
          return span;
        },
      });

      L.control.watermark = (opts) => new L.Control.Watermark(opts);

      L.control.watermark({ position: 'topright' }).addTo(this.map);
    },
    polygonActions(span) {
      this.selectPollygon = !this.selectPollygon;
      if (this.selectPollygon) {
        this.deselectSelectedAreas();
      } else {
        const pollygons = this.pollygonAreas.map((area) => [area.lat, area.lng]);
        this.markerGroups.clearLayers();
        const h3Pollygons = polyfill(pollygons, settings.h3_hexagon_resolution);
        h3Pollygons.forEach(this.selectArea);
        this.pollygonAreas = [];
      }
      span.classList.add(this.selectPollygon ? 'mdi-pentagon' : 'mdi-pentagon-outline');
      span.classList.remove(this.selectPollygon ? 'mdi-pentagon-outline' : 'mdi-pentagon');
      Object.assign(span.style, {
        backgroundColor: this.$vuetify.theme.defaults.dark[this.selectPollygon ? 'success' : 'error'],
        borderRadius: this.selectPollygon ? '10px' : '5px',
        transform: `scale(${this.selectPollygon ? 1.2 : 1})`,
      });
    },
    drawCurrentAreasWithTariff() {
      Object.values(this.currentAreas).forEach((layer) => (layer.remove()));
      this.currentAreas = {};
      this.areasWithTariff.forEach((areaWithTariff) => {
        areaWithTariff.areaKeys.forEach((areaKey) => {
          this.currentAreas[areaKey] = L.polygon(
            h3ToGeoBoundary(areaKey),
            {
              defaultColor: areaWithTariff.tariffColor,
              color: areaWithTariff.tariffColor,
              fillOpacity: 0.5,
            },
          ).addTo(this.map);
        });
      });

      this.deselectSelectedAreas();
    },
    selectArea(areaKey) {
      const isSelectedArea = areaKey in this.selectedAreas;
      if (isSelectedArea) { return; }

      const isCurrentArea = areaKey in this.currentAreas;
      if (isCurrentArea) {
        this.selectCurrentArea(areaKey);
      } else {
        this.selectNewArea(areaKey);
      }
    },
    selectNewArea(areaKey) {
      const polygon = h3ToGeoBoundary(areaKey);
      // TODO: Extend Polygon for select and deselect with color in options
      this.selectedAreas[areaKey] = L.polygon(polygon, { color: '#ff443d', fillOpacity: 0.5 }).addTo(this.map);
      this.emitChangedSelectedAreas();
    },
    selectCurrentArea(areaKey) {
      this.selectedAreas[areaKey] = this.currentAreas[areaKey];
      this.selectedAreas[areaKey].setStyle({ color: '#4caf50', fillOpacity: 0.5 });
      this.emitChangedSelectedAreas();
    },
    deselectSelectedAreas() {
      Object.keys(this.selectedAreas).forEach(this.deselectArea);
      this.selectedAreas = {};
    },
    deselectArea(areaKey) {
      const isSelectedArea = areaKey in this.selectedAreas;
      if (!isSelectedArea) { return; }

      const isCurrentArea = areaKey in this.currentAreas;
      if (isCurrentArea) {
        this.deselectCurrentArea(areaKey);
      } else {
        this.deselectNewArea(areaKey);
      }
    },
    deselectNewArea(areaKey) {
      this.selectedAreas[areaKey].remove();
      delete this.selectedAreas[areaKey];
      this.emitChangedSelectedAreas();
    },
    deselectCurrentArea(areaKey) {
      const layer = this.currentAreas[areaKey];
      const layerDefaultColor = layer.options.defaultColor;
      layer.setStyle({ color: layerDefaultColor, fillOpacity: 0.5 });
      delete this.selectedAreas[areaKey];
      this.emitChangedSelectedAreas();
    },
    areasWithTariffSelected(areaWithTariff) {
      areaWithTariff.areaKeys.forEach(this.selectArea);
    },
    emitChangedSelectedAreas() {
      this.$emit('changedSelectedAreas', Object.keys(this.selectedAreas));
    },
    clickOnMap(latLong) {
      if (this.selectPollygon) {
        this.pollygonAreas.push(latLong);
        const icon = L.icon({
          iconUrl: iconMarker,
          iconSize: [30, 40],
          iconAnchor: [15, 30],
          className: 'icon',
        });
        L.marker([latLong.lat, latLong.lng], { icon }).addTo(this.markerGroups);
      } else {
        const areaKey = geoToH3(latLong.lat, latLong.lng, settings.h3_hexagon_resolution);
        this.selectArea(areaKey);
      }
    },
    rightClickOnMap(latLong) {
      const areaKey = geoToH3(latLong.lat, latLong.lng, settings.h3_hexagon_resolution);
      this.deselectArea(areaKey);
    },
    rightClickOnRestIcon(e) {
      this.circleAreaMenu.x = e.originalEvent.clientX;
      this.circleAreaMenu.y = e.originalEvent.clientY;
      this.$nextTick().then(() => {
        this.circleAreaMenu.enabled = true;
      });
    },
    lassoSelectLatLongs(latLongs) {
      const mappedLatLng = latLongs.map((ll) => [ll.lat, ll.lng]);
      polyfill(mappedLatLng, settings.h3_hexagon_resolution).forEach(this.selectArea);
    },
    metersPerPixel(latitude, zoomLevel) {
      const earthCircumference = 40075017;
      const latitudeRadians = latitude * (Math.PI / 180);
      return (earthCircumference * Math.cos(latitudeRadians)) / (2 ** (zoomLevel + 8));
    },
    // TODO: move to mixin
    selectCircleRestaurantArea(circleRadius) {
      /* eslint no-underscore-dangle: 0 */
      const restLatLng = this.restIcon._latlng;
      const restPoint = this.map.latLngToLayerPoint(restLatLng);
      const pixelRadius = circleRadius / this.metersPerPixel(restLatLng.lat, this.map._zoom);
      const points = [];
      const countPoints = 33;
      const doublePi = Math.PI * 2;

      let angle = 0.0;
      for (let i = 0; i < countPoints - 1; i += 1) {
        angle -= (doublePi / countPoints);
        const point = new L.Point(
          restPoint.x + (pixelRadius * Math.cos(angle)),
          restPoint.y + (pixelRadius * Math.sin(angle)),
        );
        points.push(this.map.layerPointToLatLng(point));
      }
      const mappedLatLng = points.map((ll) => [ll.lat, ll.lng]);
      polyfill(mappedLatLng, settings.h3_hexagon_resolution).forEach(this.selectArea);
    },
  },
  watch: {
    restaurantLocation() {
      this.setupRestaurantLocation();
    },
    areasWithTariff: {
      deep: true,
      handler() {
        this.drawCurrentAreasWithTariff();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~leaflet/dist/leaflet.css";
@import 'src/assets/scss/page.scss';
@import 'src/assets/scss/mixin.scss';

.areas-map-wrapper{
  position: relative;
  .map {
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  .leaflet-marker-icon {
    box-shadow: 1px 1px 1px 10px black;
  }
}
</style>
