<template>
  <VAutocomplete
    v-model="selectedRestaurant"
    :append-icon="appendIcon"
    background-color="white"
    class="restaurant-search-field"
    :clearable="!readonly"
    :color="outlineColor"
    :dense="dense"
    :hide-details="!required"
    hide-no-data
    hide-selected
    item-text="title"
    :items="restaurants"
    :loading="isLoading"
    :outlined="outlined"
    :placeholder="inputPlaceholder"
    :prepend-inner-icon="searchIcon"
    :readonly="readonly"
    return-object
    :rules="required ? rules : []"
    :search-input.sync="searched"
    @click:clear="$emit('clear')"
  />
</template>

<script>
import { City } from '@/serializers/citySerializer';
import { fetchRestaurants } from '@/api/api';

export default {
  name: 'RestaurantSelection',
  props: {
    city: {
      type: City,
    },
    restaurant: {
      type: Object,
      default: () => ({}),
    },
    inputPlaceholder: {
      type: String,
      default: 'Выберите заведение',
    },
    outlineColor: {
      type: String,
      default: '',
    },
    searchIcon: {
      type: String,
      default: 'mdi-magnify',
    },
    appendIcon: {
      type: String,
      default: null,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['clear', 'restaurantSelected'],
  data() {
    return {
      selectedRestaurant: {},
      restaurants: [],
      isLoading: false,
      searched: null,
      rules: [
        (v) => !!v || 'Поле не заполнено',
      ],
    };
  },
  mounted() {
    if (this.restaurant?.title) {
      this.selectedRestaurant = this.restaurant;
      this.restaurants = this.restaurant ? [this.restaurant] : [];
    }
  },
  methods: {
    getRestaurants(text, selectRestaurant = false) {
      if (!text) {
        this.restaurants = [];
        this.selectedRestaurant = null;
        this.isLoading = false;
        return;
      }
      this.isLoading = true;
      const cityTitle = this.city ? this.city.title : null;
      fetchRestaurants(cityTitle, text).then((rests) => {
        this.restaurants = rests;
        this.isLoading = false;
        if (selectRestaurant && this.restaurants.length === 1) {
          this.selectedRestaurant = this.restaurants[0];
        }
      });
    },
  },
  watch: {
    restaurant(newRestaurant) {
      this.selectedRestaurant = newRestaurant;
      this.restaurants = [this.restaurant];
    },
    searched(text) {
      this.getRestaurants(text);
    },
    selectedRestaurant(selectedRestaurant) {
      this.$emit('restaurantSelected', selectedRestaurant);
    },
  },
};
</script>

<style lang="scss" scoped>

.v-text-field {
  min-width: 280px;
}
.v-text-field--outlined fieldset {
  border: 2px solid #C7CACE;
}
.v-input__icon--clear {
  display: none;
}
</style>
