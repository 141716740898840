<template>
  <VCol
    class="upload-csv pb-0 pt-0"
    cols="2"
    :style="{ 'margin-bottom': areaLegendsLength * 45 + 'px' }"
  >
    <VRow class="mb-0 mt-0 ">
      <VCol cols="12">
        <VCol class="upload-csv__text text-md-body-1">
          <span :class="csvText[fileState].class"> {{ csvText[fileState].text }} </span>
        </VCol>
        <VCol
          v-if="file"
          class="upload-csv__file-name"
          cols="12"
        >
          <span> {{ file.name }} </span>
          <VIcon
            color="black"
            small
            @click="removeFile"
          >
            mdi-close
          </VIcon>
        </VCol>
        <VCol class="d-flex justify-center" cols="12">
          <VBtn
            v-if="!file && fileState === 'load'"
            block
            class="white--text mb-0"
            color="green"
            large
          >
            <input
              id="actual-btn"
              ref="file"
              accept=".csv"
              hidden
              type="file"
              @change="selectFile"
            >
            <label class="white--text" for="actual-btn">
              Выбрать файл
            </label>
          </VBtn>
          <VBtn
            v-if="fileState === 'load' && file"
            block
            class="white--text mb-0"
            color="green"
            :disabled="disabledBtn"
            large
            @click="uploadFile"
          >
            {{ csvText[fileState].textBtn }}
          </VBtn>
          <VBtn
            v-if="fileState === 'loaded'"
            block
            class="white--text mb-0"
            color="green"
            large
            @click="downloadFileCSV"
          >
            {{ csvText[fileState].textBtn }}
          </VBtn>
          <VBtn
            v-if="fileState === 'reload'"
            block
            class="white--text mb-0"
            color="green"
            large
            @click="reloadFile"
          >
            {{ csvText[fileState].textBtn }}
          </VBtn>
        </VCol>
      </VCol>
    </VRow>
  </VCol>
</template>

<script>
import { uploadCsvFile } from '@/api/api';

const csvText = {
  load: {
    text: 'Выберите файл с зонами доставки в формате .csv',
    textBtn: 'Загрузить файл',
    class: '',
  },
  loaded: {
    text: 'Файл успешно загружен!',
    textBtn: 'Скачать backup',
    class: '',
  },
  reload: {
    text: 'Ошибка в загрузке файла',
    textBtn: 'Повторить',
    class: 'red--text',
  },
};

export default {
  name: 'UploadCsvFile',
  data() {
    return {
      fileState: 'load',
      file: null,
      downloadFile: null,
      downloadFileName: null,
      disabledBtn: false,
      csvText,
    };
  },
  props: {
    areaLegendsLength: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  methods: {
    selectFile(event) {
      this.file = event.target.files[0];
    },
    uploadFile() {
      if (!this.isCsvFile(this.file.name)) {
        this.reload = 'reload';
        return;
      }
      const formData = new FormData();
      formData.append('csv_file', this.file);
      this.disabledBtn = true;
      uploadCsvFile(formData)
        .then((result) => {
          if (result.status === 200) {
            this.file = null;
            this.fileState = 'loaded';
            this.downloadFile = result.data;
            const headerLine = result.headers['content-disposition'] || result.headers['Content-Disposition'];
            if (headerLine) {
              const startFileNameIndex = headerLine.indexOf('"') + 1;
              const endFileNameIndex = headerLine.lastIndexOf('"');
              this.downloadFileName = headerLine.substring(startFileNameIndex, endFileNameIndex).split('filename=')[1].split(';')[0];
            }
          } else {
            this.fileState = 'reload';
          }
        })
        .catch(() => {
          this.fileState = 'reload';
        })
        .finally(() => {
          this.disabledBtn = false;
          this.$root.$emit('updateRestaurantAreas');
        });
    },
    reloadFile() {
      this.file = null;
      this.fileState = 'load';
    },
    removeFile() {
      this.file = null;
    },
    downloadFileCSV() {
      if (this.downloadFile) {
        const url = window.URL.createObjectURL(new Blob([this.downloadFile]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.downloadFileName ? this.downloadFileName : 'file.csv');
        document.body.appendChild(link);
        link.click();
        this.downloadFile = null;
        this.downloadFileName = null;
        this.fileState = 'load';
      }
    },
    isCsvFile(filename) {
      const format = 'csv';
      const parts = filename.split('.');
      const ext = parts[parts.length - 1];
      return ext.toLowerCase() === format;
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-csv {
  position: absolute;
  bottom: 9px;
  left: 10px;
  z-index: 2;
  background-color: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  &__text {
    color: #67687D;
  }
  &__file-name {
    color: black;
    overflow-wrap: break-word;
    .v-icon {
      cursor: pointer;
    }
  }
  .v-row {
    padding: 10px;
  }
  .v-btn {
    height: 36px;
    border-radius: 6px!important;
    text-transform: none !important;
    font-size: 16px;
    label {
      cursor: pointer;
    }
  }
}
</style>
