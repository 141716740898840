<template>
  <div>
    <VSelect
      v-model="selectedCity"
      clearable
      color="success"
      dense
      hide-details
      :items="cityItems"
      :label="selectedCity ? selectedCity.title : 'Город'"
      outlined
      single-line
      @change="citySelected"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CitySelection',
  props: {
    city: [Object],
  },
  emits: ['city-selected'],
  data() {
    return {
      selectedCity: this.city || null,
      cityItems: [],
    };
  },
  created() {
    this.getCities();
  },
  computed: {
    ...mapGetters(['cities']),
  },
  methods: {
    ...mapActions(['getCities']),
    citySelected() {
      this.$emit('city-selected', this.selectedCity);
    },
  },
  watch: {
    cities(newCities) {
      this.cityItems = newCities.map(
        (city) => ({ text: city.title, value: city }),
      );
    },
    city(newCity) {
      this.selectedCity = newCity;
    },
  },
};
</script>
