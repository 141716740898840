<template>
  <div class="areas-legend">
    <div
      v-for="areaWithTariff in areasWithTariff"
      :key="areaWithTariff.tariffKey + areaWithTariff.isReducedArea"
      class="d-flex flex-column"
    >
      <div
        class="d-flex align-center px-2 selected-tariff"
        @click="areasWithTariffSelected(areaWithTariff)"
      >
        <div class="my-2 area-color" :style="{ backgroundColor:areaWithTariff.tariffColor }"></div>
        <span class="ml-4 title-leaflet-control">{{ areaWithTariff.tariffTitle }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    areasWithTariff: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['areaLegendLength', 'areasWithTariffSelected'],
  watch: {
    areasWithTariff: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit('areaLegendLength', this.areasWithTariff.length);
      },
    },
  },
  methods: {
    areasWithTariffSelected(tariff) {
      this.$emit('areasWithTariffSelected', tariff);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/leafletAreaLegend.scss';
.areas-legend{
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 2;
  background-color: #fff;
  .info-leaflet-control {
    padding: 6px 8px;
    background: red;
    box-shadow: 0 0 15px rgb(0 0 0 / 20%);
    border-radius: 5px;
  }
  .selected-tariff{
    cursor: pointer;
    transition: transform .4s, background-color .3s;
    &:hover{
      transform: scale(1.05);
      background-color: $transparent_gray_color;
    }
  }
  .area-color{
    height: 24px; width: 24px;
  }
}
</style>
